import React from "react"
import Layout from "../components/layout"
import styles from "./thanks.module.css"


export default function ContactPage() {
  return (
    <Layout>
      <div>
        <p className={styles.text}>
          Thanks for reaching out, I will get back to you soon! I look forward
          to chatting!
        </p>
      </div>
    </Layout>
  )
}
